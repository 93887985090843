/*

This interpreter is used to parse manifest codes and split them into their component parts.

For example --

manifest_code: HTTPS://WWW.HIDGLOBAL.COM/PRODUCTS/READERS/SIGNO/FL0M9U0CCPWO40KNKS221220/40KNKS-T0-000000/EA13A0F1D77E6D3701490300
From the above manifest_code we can draw out the following:

  FL0M9U0CCPWO40KNKS221220 is the serial number
  40KNKS-T0-000000 - is the stock_code - another one!! (looks actually like an SKU)
  EA13A0F1D77E6D3701490300 - who knows what this is!


manifest_code: AL560_500150,026020,00177A0102202415
From the above manifest_code we can draw out the following:

  AL560_500150 - is the stock_code!!
  026020 - is the serial_number
  00177A0102202415 is the unformatted mac_address_zigbee


manifest_code: AH40;AH40-IP_G5;A23449383;00:12:4B:00:2C:32:73:6D;2024-09-13;1.2.0
From the above manifest_code we can draw out the following:

  AH40-IP_G5 - is the mfr's sku
  A23449383 - is the serial_number
  00:12:4B:00:2C:32:73:6D is the formatted mac_address_zigbee
  AH40 is the model
  2024-09-13 is the date_of_manufacture
  1.2.0 is the firmware_version_original


manifest_code: 02369-001,B8A44F6DC15A,POLAND
From the above manifest_code we can draw out the following:

  02369-001 - is the axis_product_code
  B8A44F6DC15A - is the unformatted mac_address
  POLAND is the country_of_origin


manifest_code: 500-------40-00;6.6.32718;3.4.0
From the above manifest_code we can draw out the following:

  500-------40-00 - is the mfr's sku
  6.6.32718 - is the hardware_version
  3.4.0 - is the firmware_version_original


manifest_code: 00.17.7A.01.02.09.2A.9B;125900;AH30;6.6.32718
From the above manifest_code we can draw out the following:

  00.17.7A.01.02.09.2A.9B - is the unformatted mac_address
  125900 - is the serial_number (? I think)
  AH30 - is the model
  6.6.32718 - is the hardware_version


manifest_code: SN:4294967295 MAC:00:17:7A:01:02:13:D1:04
From the above manifest_code we can draw out the following:

  4294967295 is the serial number
  00:17:7A:01:02:13:D1:04 is the formatted mac_address_zigbee


manifest_code: 00-18-C8-2E-A8-BE
From the above manifest_code we can draw out the following:

  00-18-C8-2E-A8-BE is the mac_address

--------------------------------

*/

class ManifestInterpreter {
  constructor() {
    this.signatures = {
      HidGlobal: {
        pattern: /^HTTPS:\/\/WWW\.HIDGLOBAL\.COM\/PRODUCTS\/READERS\/SIGNO\/([A-Z0-9]+)\/([A-Z0-9-]+)\/([A-Z0-9]+)$/i,
        keys: ['serial_number', 'stock_code', 'unknown_code']
      },
      AssaAbloyStock: {
        pattern: /^([A-Za-z0-9_]+),([A-Za-z0-9]+),([0-9A-Fa-f]{16})$/i,
        keys: ['stock_code', 'serial_number', 'mac_address_zigbee']
      },
      AssaAbloy: {
        pattern: /^([^;]+);([^;]+);([^;]+);([^;]+);([^;]+);([^;]+)$/,
        keys: ['model', 'manufacturer_sku', 'serial_number', 'mac_address_zigbee', 'date_of_manufacture', 'firmware_version_original']
      },
      Axis: {
        pattern: /^([\w-]+),([A-Fa-f0-9]{12}),([\w\s.]+)$/,
        keys: ['axis_product_code', 'mac_address', 'country_of_origin']
      },
      AssaAbloySHF: {
        pattern: /^([^;]+);([\d.]+);([\d.]+)$/,
        keys: ['manufacturer_sku', 'hardware_version', 'firmware_version_original']
      },
      AssaAbloyMSMH: {
        pattern: /^([0-9A-Fa-f]{2}(?:\.[0-9A-Fa-f]{2}){7});([^;]+);([^;]+);([\d.]+)$/,
        keys: ['mac_address', 'serial_number', 'model', 'hardware_version']
      },
      AssaAbloySZ: {
        pattern: /^SN:(\d+)\s+MAC:([0-9A-Fa-f:]+)$/,
        keys: ['serial_number', 'mac_address_zigbee']
      },
      Isonas: {
        pattern: /^([0-9A-Fa-f]{2}(?:-[0-9A-Fa-f]{2}){5})$/,
        keys: ['mac_address']
      }
    };
  }

  determineSignature(manifestCode) {
    for (const [key, { pattern }] of Object.entries(this.signatures)) {
      if (pattern.test(manifestCode)) {
        return key;
      }
    }
    return "Unknown";
  }

  call(manifestCode) {
    const signature = this.determineSignature(manifestCode);
    if (signature === "Unknown") { return null; }
    const parsedManifest = this.parseManifest(manifestCode, signature);
    return this.normalizeMacAddresses(parsedManifest);
  }

  parseManifest(manifestCode, signature) {
    const { pattern, keys } = this.signatures[signature];
    const match = manifestCode.match(pattern);
    if (!match) {
      throw new Error(`Invalid ${signature} manifest code: ${manifestCode}`);
    }

    return keys.reduce((acc, key, index) => {
      if (key !== "unknown_code"){
        acc[key] = match[index + 1];
      }
      return acc;
    }, {});
  }

  normalizeMacAddresses(manifest) {
    const normalize = (macAddress) => {
      macAddress = macAddress.toLowerCase();
      if (!macAddress.includes(":")) {
        macAddress = macAddress.replace(/[.-]/g, '').replace(/(.{2})(?=.)/g, "$1:");
      }
      return macAddress;
    };

    if (manifest.mac_address_zigbee) {
      manifest.mac_address_zigbee = normalize(manifest.mac_address_zigbee);
    } else if (manifest.mac_address) {
      manifest.mac_address = normalize(manifest.mac_address);
    }

    return manifest;
  }
}

export default ManifestInterpreter;
