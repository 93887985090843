import { Controller } from "@hotwired/stimulus"
import Papa from "papaparse"
import tippy from "tippy.js"

export default class extends Controller {
  static targets = ["fileInput", "table", "actionSelect", "empty",
    "filename", "count", "importButton", "validateButton", "key", "spinner", "titleInput", "body"]
  static values = {
    model: String,
    fields: Array,
    accountId: String,
    validateUrl: String,
    importUrl: String
  }

  initialize() {
    this.selectedRows = new Set()
    this.headers = []
    this.showErrorRows = true
    this.columnTypes = {}
    this.parsedData = []
    this.chunkSize = 100 // Number of rows to process at a time
    this.currentChunk = 0
  }

  connect() {
    this.setupEventListeners()
    this.updateButtonText()
    this.stripCurrencyEnabled = false
    this.setupColumnTypes()
    this.columnMappings = {}
    this.keyColumnIndex = this.keyTarget.value
  }

  setupColumnTypes() {
    // console.log("Setting up column types")
    // console.log("Fields value:", this.fieldsValue)

    if (!this.fieldsValue || !Array.isArray(this.fieldsValue)) {
      console.error("Fields value is not properly initialized:", this.fieldsValue)
      return
    }

    this.fieldsValue.forEach(field => {
      if (typeof field === 'object' && field.name && field.type) {
        this.columnTypes[field.name] = field.type
      } else {
        console.warn("Invalid field object:", field)
      }
    })

    //console.log("Column types:", this.columnTypes)
  }

  disconnect() {
    this.removeEventListeners()
  }

  setupEventListeners() {
    this.boundHandleFileSelect = this.handleFileSelect.bind(this)
    this.boundHandleImport = this.handleImport.bind(this)
    this.boundHandleValidate = this.handleValidate.bind(this)
    this.boundKeySelect = this.handleKeySelect.bind(this)

    this.fileInputTarget.addEventListener("change", this.boundHandleFileSelect)
    this.importButtonTarget.addEventListener("click", this.boundHandleImport)
    this.validateButtonTarget.addEventListener("click", this.boundHandleValidate)
    this.keyTarget.addEventListener("change", this.boundKeySelect)
  }

  removeEventListeners() {
    this.fileInputTarget.removeEventListener("change", this.boundHandleFileSelect)
    this.importButtonTarget.removeEventListener("click", this.boundHandleImport)
    this.validateButtonTarget.removeEventListener("click", this.boundHandleValidate)
    this.keyTarget.removeEventListener("change", this.boundKeySelect)
  }

  stripCurrency(event) {
    this.performOperation(async () => {
      this.stripCurrencyEnabled = event.target.checked
      await this.validateAndRenderTableBody()
    })
  }

  handleFileSelect(event) {
    const file = event.target.files[0]

    if (file && (file.type === "text/csv" || file.type === "text/tab-separated-values")) {
      this.filenameTarget.textContent = file.name
      this.parseCSV(file)
    } else {
      this.showAlert("Please upload a valid CSV file.")
    }
  }

  showSpinner() {
    this.spinnerTarget.classList.remove('hidden')
  }

  hideSpinner() {
    this.spinnerTarget.classList.add('hidden')
  }

  async performOperation(operation) {
    this.showSpinner()
    try {
      await operation()
    } finally {
      this.hideSpinner()
    }
  }

  parseCSV(file) {
    this.showSpinner()
    this.parsedData = []
    this.currentChunk = 0

    Papa.parse(file, {
      header: false,
      dynamicTyping: false,
      skipEmptyLines: false,
      chunk: this.handleChunk.bind(this),
      complete: this.handleParseComplete.bind(this),
      error: this.handleParseError.bind(this)
    })
  }

  handleChunk(results, parser) {
    if (this.currentChunk === 0) {
      this.headers = results.data[0]
      this.processHeaderRow(this.headers)
    }

    const rows = results.data.slice(this.currentChunk === 0 ? 1 : 0)
    const processedRows = rows.map((row, index) => {
      const isStructurallyValid = this.isRowStructurallyValid(row, this.currentChunk * this.chunkSize + index + 1, this.headers.length)
      return {
        data: this.padRow(row, this.headers.length),
        isStructurallyValid: isStructurallyValid,
        isContentValid: isStructurallyValid // Initially set content validity based on structural validity
      }
    })

    this.parsedData = this.parsedData.concat(processedRows)
    this.currentChunk++
  }

  handleParseComplete(results) {
    if (this.parsedData.length === 0) {
      this.generateEmptyTable()
    } else {
      this.renderTableBody()
      this.updateRowCounts()
    }
    this.hideSpinner()
  }


  processHeaderRow(headerRow) {
    this.setupTable()
    this.renderTableHeader(headerRow)
    this.updateFieldOptions()
  }

  isRowStructurallyValid(row, rowIndex, headerCount) {
    if (rowIndex === 0) return true // Header row is always valid
    return row.length === headerCount && row.some(cell => cell.trim() !== "")
  }

  handleParseError(error) {
    console.error("Error parsing CSV:", error)
    this.showAlert("An error occurred while parsing the CSV file.")
    this.hideSpinner()
  }

  isValidCellValue(value, expectedType) {
    if (value === undefined || value === null || (typeof value === 'string' && value.trim() === '')) {
      return true  // Consider empty values as valid for now
    }

    let processedValue = this.stripCurrencyEnabled && typeof value === 'string'
      ? this.stripCurrencyFromCell(value)
      : value;

    switch (expectedType) {
      case 'number':
        return !isNaN(parseFloat(processedValue)) && isFinite(processedValue)
      case 'date':
        return !isNaN(Date.parse(processedValue))
      case 'boolean':
        return ['true', 'false', '1', '0'].includes(String(processedValue).toLowerCase())
      default: // 'string'
        return true
    }
  }

  updateRowCounts() {
    const goodRows = this.parsedData.filter(row => row.isStructurallyValid && row.isContentValid).length
    const badRows = this.parsedData.length - goodRows

    if (this.showErrorRows) {
      this.countTarget.innerHTML = `(${goodRows} valid, ${badRows} invalid)`
    } else {
      this.countTarget.innerHTML = `(${goodRows})`
    }
  }

  generateEmptyTable() {
    this.emptyTarget.textContent = "This CSV is not valid, please try again"
    this.emptyTarget.classList.remove("hidden")
    this.tableTarget.classList.add("hidden")
  }

  setupTable() {
    const table = this.tableTarget
    table.classList.remove("hidden")
    this.emptyTarget.classList.add("hidden")

    // Clear existing table content
    table.querySelector("thead").innerHTML = ""
    table.querySelector("tbody").innerHTML = ""
  }

  renderTableHeader(headerRow) {
    const thead = this.tableTarget.querySelector("thead")
    const headerRowElement = document.createElement("tr")
    headerRowElement.appendChild(this.createCheckboxCell(true))

    headerRow.forEach((header, index) => {
      const th = document.createElement("th")
      th.appendChild(this.createHeaderContent(header, index))
      headerRowElement.appendChild(th)
    })

    thead.appendChild(headerRowElement)
  }

  renderTableBody() {
    const tbody = this.tableTarget.querySelector("tbody")
    tbody.innerHTML = "" // Clear existing rows
    const fragment = document.createDocumentFragment()

    this.parsedData.forEach((row, index) => {
      const tr = this.createTableRow(row, index + 1)
      fragment.appendChild(tr)
    })

    tbody.appendChild(fragment)
    this.restoreCheckboxStates()
  }

  padRow(row, desiredLength) {
    return [...row, ...Array(desiredLength - row.length).fill("")]
  }

  restoreCheckboxStates() {
    this.tableTarget.querySelectorAll("tbody input[type='checkbox']").forEach((checkbox, index) => {
      if (this.selectedRows.has(index + 1)) {
        checkbox.checked = true
      }
    })
  }

  stripCurrencyFromCell(cell) {
    if (typeof cell !== 'string') {
      return String(cell);
    }
    return cell.replace(/[$€£¥₹₽₩₦₱₿,]/g, '').trim()
  }

  createCheckboxCell(isHeader, index, isValid = true) {
    const cell = document.createElement(isHeader ? "th" : "td")
    cell.classList.add("w-6")
    const checkbox = document.createElement("input")
    checkbox.type = "checkbox"
    if (isHeader) {
      checkbox.dataset.action = "change->importer#toggleAll"
    } else {
      checkbox.id = index.toString()
      checkbox.dataset.action = "change->importer#toggleRowSelection"
      if (!isValid) {
        checkbox.disabled = true
        checkbox.title = "This row is invalid and cannot be imported"
        checkbox.classList.add("opacity-25","cursor-not-allowed")
      } else if (this.selectedRows.has(index)) {
        checkbox.checked = true
      }
    }
    cell.appendChild(checkbox)
    return cell
  }

  toggleRowSelection(event) {
    const checkbox = event.target
    const rowIndex = parseInt(checkbox.id)

    if (checkbox.checked && !checkbox.disabled) {
      this.selectedRows.add(rowIndex)
    } else {
      this.selectedRows.delete(rowIndex)
    }

    this.updateButtonText()
  }

  toggleErrorRows(event) {
    this.performOperation(async () => {
      this.showErrorRows = event.target.checked
      const errorRows = this.tableTarget.querySelectorAll(".error-row")
      errorRows.forEach(row => {
        if (this.showErrorRows) {
          row.classList.remove("hidden")
        } else {
          row.classList.add("hidden")
        }
      })
      this.updateRowCounts()
    })
  }

  createHeaderContent(header, index) {
    const container = document.createElement("div")
    container.classList.add("flex", "flex-col", "items-start")

    const columnName = document.createElement("div")
    columnName.classList.add("font-300", "text-xs", "text-text", "mb-1")
    columnName.textContent = header
    container.appendChild(columnName)

    const fieldSelect = this.createFieldSelect(header)
    container.appendChild(fieldSelect)

    return container
  }

  createFieldSelect(header) {
    const fieldSelect = document.createElement("select")
    fieldSelect.dataset.action = "change->importer#updateColumnMapping"  // Change this line

    const emptyOption = document.createElement("option")
    emptyOption.value = ""
    emptyOption.textContent = "Select an option"
    fieldSelect.appendChild(emptyOption)

    const ignoreOption = document.createElement("option")
    ignoreOption.value = "Ignore"
    ignoreOption.textContent = "Ignore"
    fieldSelect.appendChild(ignoreOption)

    this.fieldsValue.forEach((field) => {
      const option = document.createElement("option")
      option.value = field.raw
      option.textContent = field.name
      fieldSelect.appendChild(option)
    })

    this.autoSelectOption(fieldSelect, header)

    return fieldSelect
  }

  updateColumnMapping(event) {
    this.performOperation(async () => {
      const select = event.target
      const columnIndex = Array.from(select.closest('tr').children).indexOf(select.closest('th')) - 1
      this.columnMappings[columnIndex] = this.fieldsValue.find(field => field.name === select.value)
      await this.validateAllRows()
      await this.validateAndRenderTableBody()
    })
  }

  async validateAllRows() {
    this.showSpinner()
    try {
      for (let i = 0; i < this.parsedData.length; i++) {
        this.parsedData[i].isContentValid = this.isRowContentValid(this.parsedData[i].data)

        // Yield to browser every 100 rows
        if (i % 100 === 0) {
          await new Promise(resolve => setTimeout(resolve, 0))
        }
      }
    } finally {
      this.hideSpinner()
    }
  }

  async validateAndRenderTableBody() {
    this.showSpinner()
    try {
      const tbody = this.tableTarget.querySelector("tbody")
      tbody.innerHTML = ""

      const fragment = document.createDocumentFragment()
      for (let i = 0; i < this.parsedData.length; i++) {
        const row = this.parsedData[i]
        row.isContentValid = this.isRowContentValid(row.data)
        const tr = this.createTableRow(row, i + 1)
        fragment.appendChild(tr)

        // Every 100 rows, append to tbody and yield to browser
        if (i % 100 === 0) {
          tbody.appendChild(fragment)
          fragment.innerHTML = ""
          await new Promise(resolve => setTimeout(resolve, 0))
        }
      }
      tbody.appendChild(fragment)
      this.updateRowCounts()
      this.updateButtonText()
      // this.logRowValidity()
    } finally {
      this.hideSpinner()
    }
  }

  createTableRow(row, rowIndex) {
    const tr = document.createElement("tr")
    const isValid = row.isStructurallyValid && row.isContentValid
    if (!isValid) {
      tr.classList.add("bg-red-100", "error-row")
      if (!this.showErrorRows) {
        tr.classList.add("hidden")
      }
    }
    tr.appendChild(this.createCheckboxCell(false, rowIndex, isValid))
    row.data.forEach((cell, cellIndex) => {
      const td = document.createElement("td")
      const processedValue = this.stripCurrencyEnabled ? this.stripCurrencyFromCell(cell) : cell
      td.textContent = processedValue
      const mapping = this.columnMappings[cellIndex]
      if (mapping && !this.isValidCellValue(cell, mapping.type)) {
        td.classList.add("text-red-500")
      }
      td.dataset.value = processedValue
      tr.appendChild(td)
    })
    return tr
  }

  isRowContentValid(rowData) {
    return rowData.every((cell, cellIndex) => {
      const mapping = this.columnMappings[cellIndex]
      if (!mapping || mapping.name === 'Ignore') return true
      return this.isValidCellValue(cell, mapping.type)
    })
  }

  autoSelectOption(select, header) {
    const normalizedHeader = header.trim().toLowerCase();
    const options = this.fieldsValue;
    const scores = new Map();

    options.forEach(field => {
      const fieldName = field.name.toLowerCase();
      let score = 0;

      if (fieldName === normalizedHeader) {
        score += 100;
      } else if (normalizedHeader.includes(fieldName)) {
        score += 75;
      }

      const fieldWords = fieldName.split(/\s+/);
      fieldWords.forEach(word => {
        if (normalizedHeader.includes(word)) {
          score += 25;
        }
      });

      if (score > 0) {
        scores.set(field.raw, score); // Use field.raw instead of field.name
      }
    });

    // Find the best match
    let bestMatch = null;
    let highestScore = 0;

    scores.forEach((score, fieldValue) => {
      if (score > highestScore) {
        highestScore = score;
        bestMatch = fieldValue;
      }
    });

    // Find and select the matching option
    const selectOptions = Array.from(select.options);
    const matchingOption = selectOptions.find(option => option.value === bestMatch);

    if (matchingOption) {
      matchingOption.selected = true;
    } else {
      const ignoreOption = selectOptions.find(option => option.value === "Ignore");
      if (ignoreOption) ignoreOption.selected = true;
    }
  }

  updateFieldOptions() {
    const selects = this.tableTarget.querySelectorAll("thead select")

    this.updateButtonText()

    selects.forEach((select) => {
      const currentValue = select.value
      select.innerHTML = ""

      const ignoreOption = document.createElement("option")
      ignoreOption.value = "Ignore"
      ignoreOption.textContent = "Ignore"
      select.appendChild(ignoreOption)

      this.fieldsValue.forEach((field) => {
        const option = document.createElement("option")
        option.value = field.raw
        option.textContent = field.name
        select.appendChild(option)
      })

      // Restore the previously selected value if it still exists
      if (select.querySelector(`option[value="${currentValue}"]`)) {
        select.value = currentValue
      } else {
        this.autoSelectOption(select, select.previousElementSibling.textContent)
      }
    })

  }

  handleKeySelect(event) {
    this.performOperation(async () => {
      const selectedKeyField = event.target.value;
      const mappedColumns = Array.from(this.tableTarget.querySelectorAll("thead select"))
        .map(select => select.value)
        .filter(value => value !== "Ignore");

      if (!mappedColumns.includes(selectedKeyField)) {
        this.showAlert("The selected key field must be one of the mapped columns. Please adjust your column mappings or select a different key field.");
      } else {
        this.keyColumnIndex = selectedKeyField;
      }
    })
  }

  toggleAll(event) {
    const { checked } = event.target
    this.tableTarget.querySelectorAll("tbody input[type='checkbox']").forEach((checkbox, index) => {
      const rowIndex = index + 1
      const row = this.parsedData[index]
      if (row && row.isStructurallyValid && row.isContentValid) {
        checkbox.checked = checked
        if (checked) {
          this.selectedRows.add(rowIndex)
        } else {
          this.selectedRows.delete(rowIndex)
        }
      }
    })
    this.updateButtonText()
  }

  countValidSelectedRows() {
    return Array.from(this.selectedRows).filter(rowIndex => {
      const row = this.parsedData[rowIndex - 1];
      return row && row.isStructurallyValid && row.isContentValid;
    }).length;
  }

  logRowValidity() {
    console.log("Row validity:");
    this.parsedData.forEach((row, index) => {
      console.log(`Row ${index + 1}: Structural: ${row.isStructurallyValid}, Content: ${row.isContentValid}`);
    });
  }

  updateButtonText() {
    const action = this.actionSelectTarget.value
    const validCheckedCount = this.countValidSelectedRows()
    const itemsText = validCheckedCount > 1 ? "items" : "item";

    if (validCheckedCount > 0) {
      this.validateButtonTarget.textContent = `Validate ${validCheckedCount} ${itemsText}`
      this.validateButtonTarget.classList.remove("opacity-50", "pointer-events-none")
      this.importButtonTarget.textContent = `${action} ${validCheckedCount} ${itemsText}`
      this.importButtonTarget.classList.remove("opacity-50", "pointer-events-none")
    } else {
      this.validateButtonTarget.textContent = `Validate 0 items`
      this.validateButtonTarget.classList.add("opacity-50", "pointer-events-none")
      this.importButtonTarget.textContent = `${action} 0 items`
      this.importButtonTarget.classList.add("opacity-50", "pointer-events-none")
    }
  }

  async handleImport() {
    if (this.parsedData.length === 0) {
      this.showAlert("No data has been uploaded.")
      return
    }

    if (this.keyColumnIndex === null) {
      this.showAlert("Please select a key column.")
      return
    }

    const originalButtonText = this.importButtonTarget.textContent
    this.importButtonTarget.textContent = "Processing..."
    this.importButtonTarget.disabled = true
    this.showSpinner()

    try {
      const importData = await this.prepareImportData()

      const formData = new FormData()
      formData.append('title', this.titleInputTarget.value)
      formData.append('spreadsheet', this.fileInputTarget.files[0])

      formData.append('model', importData.model)
      formData.append('keyField', importData.keyField)

      formData.append('fields', JSON.stringify(importData.fields))
      formData.append('data', JSON.stringify(importData.data))

      this.bodyTarget.querySelectorAll('input, select').forEach(input => {
        if (input.name && input.value) {
          formData.append(input.name, input.value)
        }
      })

      const result = await fetch(this.importUrlValue, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': this.getCSRFToken()
        },
        body: formData
      })

      const json = await result.json()
      const isValid = this.highlightErrors(json.rows)
      if (isValid) {
        this.highlightSaved(json.rows);
        this.updateButtonText();
      }
    } catch (error) {
      console.error("Import error:", error)
      this.showAlert(`An error occurred during import: ${error.message}. Please try again.`)
    } finally {
      this.importButtonTarget.disabled = false
      this.importButtonTarget.textContent = originalButtonText
      this.hideSpinner()
    }
  }

  async handleValidate() {
    if (this.parsedData.length === 0) {
      this.showAlert("No data has been uploaded.")
      return
    }

    if (this.keyColumnIndex === null) {
      this.showAlert("Please select a key column.")
      return
    }

    const originalButtonText = this.validateButtonTarget.textContent
    this.validateButtonTarget.textContent = "Processing..."
    this.validateButtonTarget.disabled = true
    this.showSpinner()

    try {
      const importData = await this.prepareImportData()
      this.bodyTarget.querySelectorAll('input, select').forEach(input => {
        if (input.name && input.value) {
          importData[input.name] = input.value;
        }
      })

      const result = await fetch(this.validateUrlValue, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.getCSRFToken()
        },
        body: JSON.stringify(importData)
      })
      const json = await result.json()
      this.highlightErrors(json.rows)
    } catch (error) {
      console.error("Import error:", error)
      this.showAlert(`An error occurred during import: ${error.message}. Please try again.`)
    } finally {
      this.validateButtonTarget.textContent = originalButtonText
      this.validateButtonTarget.disabled = false
      this.hideSpinner()
    }
  }

  highlightSaved(objects) {
    const headers = Array.from(this.tableTarget.querySelectorAll("thead select")).map(select => select.value);
    const keyFieldIndex = headers.indexOf(this.keyColumnIndex);
    let savedCount = 0;

    objects.forEach((importObject, index) => {
      const keyValue = importObject[this.keyTarget.value];
      if (!keyValue) return
      const cellElement = this.tableTarget.querySelector(`td[data-value="${keyValue}"]`)
      const rowElement = cellElement.closest("tr");

      if (importObject.errors && importObject.errors.length === 0) {
        savedCount++;

        const checkbox = rowElement.querySelector("input[type='checkbox']")
        const checkboxParent = checkbox.closest("td")
        checkbox.checked = false;
        checkbox.dispatchEvent(new Event('change'));
        checkbox.remove();

        rowElement.querySelector("span.saved-icon")?.remove()
        rowElement.querySelector("span.error-icon")?.remove()
        rowElement.classList.remove("bg-red-100", "error-row")
        rowElement.classList.add("bg-green-100", "saved-row")

        const iconElement = document.createElement("span")
        iconElement.classList.add("saved-icon", "mr-1", "px-1", "py-1", "rounded-full", "text-green-500", "text-xs", "font-bold")
        iconElement.innerHTML = "&#10003;"
        checkboxParent.insertBefore(iconElement, checkboxParent.firstChild)
        checkboxParent.classList.add("font-bold", "text-green-800")
      }
    })

    if (savedCount > 0) {
      this.importButtonTarget.innerHTML = `${savedCount} saved ✓`;
    } else {
      this.importButtonTarget.innerHTML = `${0} saved`;
    }

    this.importButtonTarget.classList.remove("pointer-events-none");
    this.importButtonTarget.classList.remove("opacity-50");
  }

  highlightErrors(objects) {
    const headers = Array.from(this.tableTarget.querySelectorAll("thead select")).map(select => select.value);
    const keyFieldIndex = headers.indexOf(this.keyColumnIndex);
    let errorCount = 0;

    objects.forEach((importObject, index) => {
      const keyValue = importObject[this.keyTarget.value];
      if (!keyValue) return
      const cellElement = this.tableTarget.querySelector(`td[data-value="${keyValue}"]`)
      const rowElement = cellElement.closest("tr");

      if (importObject.errors && importObject.errors.length > 0) {
        errorCount++;

        // insert warning icon and use tippy to indicate the errors
        cellElement.querySelector("span.error-icon")?.remove()

        rowElement.classList.add("bg-red-100", "error-row")
        const iconElement = document.createElement("span")
        iconElement.classList.add("error-icon", "mr-1", "px-1", "py-1", "rounded-full", "text-red-500", "text-xs", "font-bold")
        iconElement.innerHTML = "&#9888;"
        cellElement.insertBefore(iconElement, cellElement.firstChild)
        cellElement.classList.add("font-bold", "text-red-800")

        tippy(iconElement, {
          content: importObject.errors.join("<br>"),
          allowHTML: true,
          placement: "right"
        })
      }
    })

    if (errorCount > 0) {
      this.validateButtonTarget.innerHTML = `${errorCount} errors`;
      this.importButtonTarget.classList.add("opacity-50");
      this.importButtonTarget.classList.add("pointer-events-none");
      return false;
    } else {
      this.validateButtonTarget.innerHTML = `${0} errors`;
      this.importButtonTarget.classList.remove("pointer-events-none");
      this.importButtonTarget.classList.remove("opacity-50");
      return true;
    }
  }

  async prepareImportData() {
    return new Promise((resolve, reject) => {
      try {
        const headers = Array.from(this.tableTarget.querySelectorAll("thead select")).map(select => select.value);
        const mappedHeaders = headers.filter(header => header !== "Ignore");

        if (!this.keyColumnIndex || !mappedHeaders.includes(this.keyColumnIndex)) {
          throw new Error("The selected key field is not valid or not mapped. Please select a valid key field and ensure it's mapped.");
        }

        const validHeaderIndices = headers.reduce((indices, header, index) => {
          if (header !== "Ignore") indices.push(index);
          return indices;
        }, []);

        const selectedDataRows = this.parsedData
          .filter((row, index) =>
            row.isStructurallyValid && row.isContentValid &&
            this.selectedRows.has(index + 1)
          );

        if (selectedDataRows.length === 0) {
          throw new Error("No valid rows selected for import.");
        }

        const importData = {
          model: this.modelValue,
          keyField: this.keyColumnIndex,
          fields: mappedHeaders, //validHeaderIndices.map(index => this.headers[index]),
          data: selectedDataRows.map(row =>
            validHeaderIndices.map(colIndex => {
              const cellValue = row.data[colIndex];
              return this.stripCurrencyEnabled && typeof cellValue === 'string'
                ? this.stripCurrencyFromCell(cellValue)
                : cellValue;
            })
          )
        };

        resolve(importData);
      } catch (error) {
        reject(error);
      }
    });
  }

  showAlert(message) {
    alert(message) // Consider replacing with a more user-friendly notification system
  }

  getCSRFToken() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
}
