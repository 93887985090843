// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
//  <div data-controller="net-tax-total">
//
//    INPUTS
//
//    <%= form.number_field :quantity, data: {
//      field: "quantity",
//      "net-tax-total-target": "quantity",
//      action: "net-tax-total#calculate"
//    } %>
//
//    <%= form.number_field :price, data: {
//      field: "price",
//      "net-tax-total-target": "price",
//      action: "net-tax-total#calculate"
//    } %>
//
//    <%= form.select :tax_code_id, options_for_select(options), data: {
//      field: "tax_code_id",
//      "net-tax-total-target": "taxcode",
//      action: "net-tax-total#calculate"
//    } %>
//
//    OUTPUTS
//
//    <div data-net-tax-total-target="net">
//    </div>
//    <div data-net-tax-total-target="tax">
//    </div>
//    <div data-net-tax-total-target="tax">
//    </div>
//
//  </div>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "net", "tax", "total",
    "price", "quantity", "taxcode"
  ]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields";
  }

  calculate() {
    // Input values
    var price = this.priceTarget.value
    var quantity = this.quantityTarget.value
    var tax_rate = this.taxcodeTarget.selectedOptions[0].dataset.rate

    // Maths
    var net_amount;
    var tax_amount;
    var total_amount;

    // Update Net
    if (price && quantity) {
      net_amount = parseFloat(
        price * quantity
      ).toFixed(2)
    } else {
      net_amount = "-"
    }

    // Update Tax
    if (price && quantity && tax_rate) {
      tax_amount = parseFloat(
        net_amount * (tax_rate / 100)
      ).toFixed(2)
    } else {
      tax_amount = "-"
    }

    this.netTarget.innerHTML = net_amount
    this.taxTarget.innerHTML = tax_amount

    // Update Total
    if (this.hasTotalTarget) {
      if (price && quantity && tax_rate) {
        total_amount = parseFloat(
          net_amount + tax_amount
        ).toFixed(2)
      } else {
        total_amount = "-"
      }
      this.totalTarget.innerHTML = total_amount
    }

    this.updateSubtotals()
  }

  updateSubtotals() {
    var net_subtotal_field = document.getElementById('net-subtotal');
    var tax_subtotal_field = document.getElementById('tax-subtotal');
    var total_subtotal_field = document.getElementById('total-subtotal');
    var total_buy_price_field = document.getElementById('total-buy-price');
    var total_margin_field = document.getElementById('total-margin');
    var margin_percentage_field = document.getElementById('margin-percentage');

    // Update Net subtotal
    if (net_subtotal_field) {
      var net_fields = $("[data-net-tax-total-target='net']:visible")
      var net_subtotal = 0.0;

      net_fields.each( function(index, element) {
        net_subtotal += parseFloat(element.textContent) || 0
      })

      net_subtotal_field.innerHTML = parseFloat(net_subtotal).toFixed(2)
    }

    // Update Tax subtotal
    if (tax_subtotal_field) {
      var tax_fields = $("[data-net-tax-total-target='tax']:visible")
      var tax_subtotal = 0.0;

      tax_fields.each( function(index, element) {
        tax_subtotal += parseFloat(element.textContent) || 0
      })

      tax_subtotal_field.innerHTML = parseFloat(tax_subtotal).toFixed(2)
    }

    // Update Total subtotal
    if (total_subtotal_field) {
      total_subtotal_field.innerHTML = parseFloat(net_subtotal + tax_subtotal).toFixed(2)
    }

    // Update Total buy price
    if (total_buy_price_field) {
      var active_line_items = $(".line-item:visible")
      var buy_price_total = 0.0;

      active_line_items.each( function(index, element) {
        const buy_price_field = $(element).find("[data-net-tax-total-target='buyPrice']")
        const quantity_field = $(element).find("[data-net-tax-total-target='quantity']")
        buy_price_total += (parseFloat(buy_price_field.val()) || 0) * (parseFloat(quantity_field.val()) || 0)
      })

      total_buy_price_field.innerHTML = parseFloat(buy_price_total).toFixed(2)
    }

    // Update Total margin
    if (total_margin_field) {
      var total_margin = parseFloat(net_subtotal_field.textContent) - parseFloat(total_buy_price_field.textContent)
      total_margin_field.innerHTML = parseFloat(total_margin).toFixed(2)
    }

    // Update Margin percentage
    if (margin_percentage_field) {
      var margin_percentage = (parseFloat(total_margin_field.innerHTML) / parseFloat(net_subtotal_field.innerHTML)) * 100
      if (isNaN(margin_percentage)) {
        margin_percentage_field.innerHTML = "-"
      } else {
        margin_percentage_field.innerHTML = parseFloat(margin_percentage).toFixed(2)
      }
    }
  }
}
