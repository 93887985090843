import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import MicroModal from "micromodal";

export default class extends Controller {
  static targets = ["contact", "company", "currency", "products", "lineItem"]

  static values = {
    company: Object,
    products: { type: Array, default: [] },
    accountId: String,
    currencyId: { type: Number, default: -1 }
  }

  connect() {
    this.initializeCurrencyTip()
    this.initializeCompanyCurrencyTip()

    if (this.companyTarget.value === "") {
      console.debug("No company selected")
    } else {
      this.fetchCompany();
    }

    this.fetchProducts()
  }

  selectCompany(event){
    this.fetchCompany();
  }

  currencyTargetConnected(){
    this.currencyChanged()
  }

  currencyChanged(){
    this.currencyIdValue = parseInt(this.currencyTarget.value);

    if (this.currencyTarget.value === ""){
      $('a[data-action="click->nested-form#add"]').addClass('disabled');
      $('#currency-information').removeClass('hidden');
    } else {
      $('a[data-action="click->nested-form#add"]').removeClass('disabled');
      $('#currency-information').addClass('hidden');
    }
  }

  fetchCompany = async () => {
    if (this.companyTarget.value !== "") {
      try {
        const response = await fetch(`/a/${this.accountIdValue}/companies/${this.companyTarget.value}.json`);
        const data = await response.json();
        this.companyValue = data;
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    }
  }

  companyValueChanged() {
    const activeCount = this.activeLineItems().length
    if (this.companyValue && activeCount === 0) {
      this.currencyTarget.value = this.companyValue.currency_id;
      this.currencyTarget.dispatchEvent(new Event('change'));
    } else if (this.companyValue && activeCount > 0 && parseInt(this.currencyTarget.value) !== this.companyValue.currency_id) {
      if (this.companyCurrencyTip) { this.companyCurrencyTip.show(); }
    } else {
      console.debug("Company is blank")
    }
  }

  fetchProducts = async () => {
    try {
      const response = await fetch(`/a/${this.accountIdValue}/products.json`)
      const data = await response.json();
      this.productsValue = data;
    } catch (error) {
      console.error('Error fetching products data:', error);
      this.productsValue = [];
    }
  }

  addItem(event) {
    this.renderFilteredProducts(this.productsValue);
    MicroModal.show("quote-products");
  }

  renderFilteredProducts(products) {
    const currencyId = parseInt(this.currencyTarget.value) || -1;
    const result = products
      .map((product) => {
        const salePrice = product.currency_sale_prices[currencyId] || 'N/A';
        return `
          <div class="grid grid-cols-[2fr_1fr_4fr_1fr_auto] gap-4 items-center py-2">
            <span class="grow truncate">${product.sku}</span>
            <span class="grow">${new Date(product.updated_at).toLocaleDateString('en-GB')}</span>
            <span class="grow truncate">${product.name}</span>
            <span class="price justify-self-end">${salePrice}</span>
            <button type="button" class="btn btn-primary" data-sku="${product.sku}">Add</button>
          </div>
        `})
      .join('');

    this.productsTarget.innerHTML = result;
    this.productsTarget.querySelectorAll('button').forEach(button => {
      button.addEventListener('click', this.addProduct);
    });
  }

  searchProducts = (event) => {
    const searchQuery = event.target.value.toLowerCase();

    if (!this.productsValue) return;

    const filteredProducts = this.productsValue.filter(product =>
      (
        product.name.toLowerCase().includes(searchQuery) ||
        product.sku.toLowerCase().includes(searchQuery)
      )
    );

    this.renderFilteredProducts(filteredProducts);
  }

  addProduct = (event) => {
    const currencyId = parseInt(this.currencyTarget.value) || -1;
    const productSku = event.target.dataset.sku;
    const selectedProductObject = this.productsValue.find(product => product.sku === productSku);

    const nestedFormElement = document.querySelector('[data-controller*="nested-form"]');
    const nestedFormController = this.application.getControllerForElementAndIdentifier(nestedFormElement, "nested-form");

    if (nestedFormController) {
      nestedFormController.add(event, selectedProductObject);
    }

    const reversedLineItems = [...this.lineItemTargets].reverse();
    const newLineItem = reversedLineItems.find(item => item.dataset.productId === productSku);
    const salePrice = selectedProductObject.currency_sale_prices[currencyId] || 'N/A';

    if (newLineItem) {
      newLineItem.querySelector('[data-field="sku"]').value = selectedProductObject.sku;
      newLineItem.querySelector('[data-field="product_id"]').value = selectedProductObject.id;
      newLineItem.querySelector('[data-field="description"]').value = selectedProductObject.name;
      newLineItem.querySelector('[data-field="quantity"]').value = 1;
      newLineItem.querySelector('[data-field="price"]').value = salePrice;
      newLineItem.querySelector('[data-field="buy_price"]').value = selectedProductObject.currency_purchase_costs?.[currencyId] || 0;
    }
  }

  initializeCurrencyTip() {
    this.currencyTip = tippy(this.currencyTarget.parentElement, {
      trigger: 'mouseenter',
      content: "You must remove line items before changing currency",
      duration: [0, 1000],
      hideOnClick: true,
      interactive: true,
      showOnCreate: false
    });
    this.currencyTip.disable();
  }

  initializeCompanyCurrencyTip() {
    this.companyCurrencyTip = tippy(this.companyTarget.parentElement, {
      trigger: 'manual',
      content: "This company uses a different default currency from currently selected quote currency.",
      duration: [0, 1000],
      hideOnClick: true,
      interactive: true,
      showOnCreate: false
    });
  }

  activeLineItems() {
    return this.lineItemTargets.filter(item => item.querySelector('[data-field="destroy"]').value !== "1");
  }

  determineLockCurrency() {
    const activeItems = this.activeLineItems();
    this.lockCurrency(activeItems.length > 0)
  }

  lockCurrency = (isLocked) => {
    this.currencyTarget.classList.toggle("pointer-events-none", isLocked);
    this.currencyTarget.classList.toggle("bg-gray-100", isLocked);
    if (this.currencyTip) {
      isLocked ? this.currencyTip.enable() : this.currencyTip.disable();
    }
  }

  lineItemTargetConnected() {
    this.determineLockCurrency()
  }

  lineItemTargetDisconnected() {
    this.determineLockCurrency()
  }

  productsValueChanged() {
    this.lineItemTargets.forEach(item => {
      this.checkForUpdatedItemPrice(item)
      this.checkForUpdatedItemBuyPrice(item)
      this.checkForUpdatedItemDescription(item)
    });
  }

  handleBuyPriceChange(event) {
    const lineItem = event.target.closest('[data-lineitem-id]')
    this.checkForUpdatedItemBuyPrice(lineItem)
    this.checkForUpdatedItemPrice(lineItem)
  }

  handlePriceChange(event) {
    const lineItem = event.target.closest('[data-lineitem-id]')
    this.checkForUpdatedItemPrice(lineItem)
  }

  checkForUpdatedItemPrice(lineItem) {
    if (this.productsValue.length === 0) { return false; }

    const productId = parseInt(lineItem.querySelector('[data-field="product_id"]').value);
    const product = this.productsValue.find(product => product.id === productId);

    const salePrice = parseFloat(product.currency_sale_prices[this.currencyIdValue] || 0);
    const fieldBuyPrice = parseFloat(lineItem.querySelector('[data-field="buy_price"]').value) || undefined;
    const buyPrice = fieldBuyPrice || parseFloat(product?.currency_purchase_costs?.[this.currencyIdValue] || 0);

    const priceField = lineItem.querySelector('[data-field="price"]')
    const historicalPrice = parseFloat(priceField.value) || 0;

    priceField.classList.remove("field-changed", "field-changed-down", "field-changed-up", "field-changed-high")

    if (historicalPrice !== salePrice) {
      // We are using a different price than recorded for this product
      priceField.classList.add("field-changed")
      if (historicalPrice <= buyPrice) {
        priceField.classList.add("field-changed-down")  // RED
      } else if (historicalPrice > buyPrice && historicalPrice < salePrice) {
        priceField.classList.add("field-changed-up")    // YELLOW
      } else if (historicalPrice >= salePrice) {
        priceField.classList.add("field-changed-high")  // GREEN
      }
    }
  }

  checkForUpdatedItemBuyPrice(lineItem) {
    if (this.productsValue.length === 0) { return false; }

    const productId = parseInt(lineItem.querySelector('[data-field="product_id"]').value);
    const product = this.productsValue.find(product => product.id === productId);
    const buyPrice = parseFloat(product?.currency_purchase_costs?.[this.currencyIdValue] || 0);
    const buyPriceField = lineItem.querySelector('[data-field="buy_price"]')
    const historicalBuyPrice = parseFloat(buyPriceField.value) || 0;

    buyPriceField.classList.remove("field-changed", "field-changed-down", "field-changed-up", "field-changed-high")

    if (historicalBuyPrice !== buyPrice) {
      buyPriceField.classList.add("field-changed")
    }
  }

  handleDescriptionChange(event) {
    const lineItem = event.target.closest('[data-lineitem-id]')
    this.checkForUpdatedItemDescription(lineItem)
  }

  checkForUpdatedItemDescription(lineItem) {
    if (this.productsValue.length === 0) { return false; }

    const productId = parseInt(lineItem.querySelector('[data-field="product_id"]').value);
    const product = this.productsValue.find(product => product.id === productId);

    const descriptionField = lineItem.querySelector('[data-field="description"]')
    const historicalDescription = descriptionField.value || "";
    const currentDescription = product.name || "";

    if (historicalDescription === currentDescription) {
      descriptionField.classList.remove("field-changed")
    } else {
      descriptionField.classList.add("field-changed")
    }
  }

  updateItemPrice(event) {
    const sku = event.target.dataset.sku;
    const lineItem = document.querySelector(`[data-product-id="${sku}"]`)
    const product = this.productsValue.find(product => product.sku === sku);
    lineItem.querySelector('[data-field="price"]').value = product.currency_sale_prices[this.currencyIdValue] || '';
    this.checkForUpdatedItemPrice(lineItem)
    MicroModal.close("product-changed");
  }

  updateItemDescription(event) {
    const sku = event.target.dataset.sku;
    const lineItem = document.querySelector(`[data-product-id="${sku}"]`)
    const product = this.productsValue.find(product => product.sku === sku);
    lineItem.querySelector('[data-field="description"]').value = product.name;
    this.checkForUpdatedItemDescription(lineItem)
    MicroModal.close("product-changed");
  }

  updateItemBuyPrice(event) {
    const sku = event.target.dataset.sku;
    const lineItem = document.querySelector(`[data-product-id="${sku}"]`)
    const product = this.productsValue.find(product => product.sku === sku);
    lineItem.querySelector('[data-field="buy_price"]').value = product.currency_purchase_costs[this.currencyIdValue] || '';
    this.checkForUpdatedItemBuyPrice(lineItem)
    MicroModal.close("product-changed");
  }

  showProductChanged(event) {
    const lineItem = event.target.closest('[data-lineitem-id]');
    const productSku = lineItem.dataset.productId;
    const product = this.productsValue.find(product => product.sku === productSku);
    const modal = document.querySelector("#product-changed");

    modal.querySelector("h2").textContent = `Product changed (${product.sku})`;
    modal.querySelector("span[data-field='line-item-description']").textContent = lineItem.querySelector('[data-field="description"]').value;
    modal.querySelector("span[data-field='product-description']").textContent = product.name;
    modal.querySelector("span[data-field='line-item-price']").textContent = lineItem.querySelector('[data-field="price"]').value;
    modal.querySelector("span[data-field='product-price']").textContent = product.currency_sale_prices[this.currencyIdValue] || 'N/A';
    modal.querySelector("span[data-field='updated_at']").textContent = new Date(product.updated_at).toLocaleDateString('en-GB');
    const buyPrice = product?.currency_purchase_costs?.[this.currencyIdValue]  === undefined ? 'N/A' : product?.currency_purchase_costs?.[this.currencyIdValue];
    modal.querySelector("span[data-field='line-item-buy-price']").textContent = lineItem.querySelector('[data-field="buy_price"]').value;
    modal.querySelector("span[data-field='product-buy-price']").textContent = buyPrice;
    modal.querySelectorAll("button.btn-primary").forEach(button => {
      button.dataset.sku = productSku;
    });

    MicroModal.show("product-changed");
  }
}
