import { Controller } from "@hotwired/stimulus"
import MicroModal from 'micromodal';

export default class extends Controller {
  static targets = [ "modal", "stockCodeInput", "searchResults", "searchResultTemplate", "queryInput", "selectedProductWrapper" ]

  connect() {
    MicroModal.init();
  }

  openModal(event) {
    const stockCode = event.currentTarget.dataset.stockCode;
    this.stockCodeInputTarget.value = stockCode;
    MicroModal.show('map-stock-code-modal');
    this.queryInputTarget.dispatchEvent(new Event('input', { bubbles: true }));
  }

  closeModal() {
    MicroModal.close('map-stock-code-modal');
  }

  searchProducts(event) {
    const query = event.target.value;
    if (query.length < 2) {
      this.searchResultsTarget.innerHTML = "";
      return;
    }

    this.searchResultsTarget.innerHTML = "<tr><td colspan='4'>Searching...</td></tr>";

    fetch(`/a/${this.element.dataset.accountId}/products/search?query=${encodeURIComponent(query)}`, {
      headers: { "Accept": "application/json" }
    })
    .then(response => response.json())
    .then(data => {
      this.displayResults(data);
    });
  }

  displayResults(data) {
    this.searchResultsTarget.innerHTML = "";
    data.forEach(result => {
      const resultElement = this.searchResultTemplateTarget.content.cloneNode(true);
      const row = resultElement.querySelector('tr');
      row.setAttribute("data-product-id", result.id);
      const spans = row.querySelectorAll('[data-field]');
      spans.forEach(span => {
        const field = span.dataset.field;
        span.textContent = result[field] || '';
      });
      this.searchResultsTarget.appendChild(resultElement);
    });
  }

  selectProduct(event) {
    const row = event.target.closest("tr");
    const productId = row.dataset.productId;
    const productName = row.querySelector('[data-field="name"]').textContent;
    const productSku = row.querySelector('[data-field="sku"]').textContent;

    // Update the hidden product_id field
    const productIdField = this.element.querySelector('input[name="product_id"]');
    if (productIdField) {
      productIdField.value = productId;
    }

    // Update the display of the selected product (you might want to add a target for this)
    const selectedProductDisplay = this.element.querySelector('[data-stock-code-mapping-target="selectedProduct"]');
    if (selectedProductDisplay) {
      selectedProductDisplay.textContent = `${productName} (${productSku})`;
    }
  }
}