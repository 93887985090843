import { Controller } from "@hotwired/stimulus"
import ManifestInterpreter from "../utils/manifest_interpreter"
export default class extends Controller {
  static targets = ["metaTable", "rowTemplate", "searchResults", "searchResultTemplate",
    "productId", "productInfo", "stockCode", "searchResultsContainer", "manifestCode", "interpretMessage", "identifier"]

  static values = {
    accountId: String
  }

  connect() {
    if (this.productIdTarget.value.length === 0) {
      this.searchResultsContainerTarget.classList.remove("hidden");
      this.productInfoTarget.classList.add("hidden");
      this.stockCodeTarget.dispatchEvent(new Event('input', { bubbles: true }));
    } else {
      this.searchResultsContainerTarget.classList.add("hidden");
      this.productInfoTarget.classList.remove("hidden");
    }
  }

  ignoreEnter(event) {
    if (event.key === "Enter"){
      event.preventDefault();
      this.advanceFocus(event);
    }
  }

  advanceFocus(event) {
    const inputs = [this.identifierTarget, this.stockCodeTarget, this.manifestCodeTarget];
    const currentIndex = inputs.indexOf(event.target);
    const nextInput = inputs[currentIndex + 1]
    if (nextInput){
      nextInput.focus();
    }
  }

  handleNewLine(event) {
    if (event.data && event.data.endsWith("\n")) {
      this.advanceFocus(event);
    }
  }

  addRow() {
    const newRow = this.rowTemplateTarget.innerHTML
    this.metaTableTarget.insertAdjacentHTML("beforeend", newRow)
    return newRow;
  }

  removeRow(event) {
    event.target.closest("tr").remove()
  }

  searchStockCodes(event) {
    this.productInfoTarget.classList.add("hidden");
    this.searchResultsContainerTarget.classList.remove("hidden");
    const query = event.target.value
    if (query.length < 2) {
      this.searchResultsTarget.innerHTML = ""
      return
    }

    this.searchResultsTarget.innerHTML = "<p>Searching...</p>"

    fetch(`/a/${this.accountIdValue}/stock_codes/search?query=${encodeURIComponent(query)}`, {
      headers: { "Accept": "application/json" }
    })
    .then(response => response.json())
    .then(data => {
      this.displayResults(data)
    })

    this.handleNewLine(event);
  }

  displayResults(data) {
    this.searchResultsTarget.innerHTML = "";
    data.forEach(result => {
      const resultElement = this.searchResultTemplateTarget.content.cloneNode(true);
      const row = resultElement.querySelector('tr');  // Get the tr element
      row.setAttribute("data-product-id", result.product_id);
      const spans = row.querySelectorAll('[data-field]');
      spans.forEach(span => {
        const field = span.dataset.field;
        span.textContent = result[field] || '';
      });
      this.searchResultsTarget.appendChild(resultElement);
    });

    if (data.length === 1 && data[0].code.toLowerCase() === this.stockCodeTarget.value.toLowerCase()) {
      this.searchResultsTarget.querySelector('button[data-action="click->inventory-form#selectStockCode"]').classList.toggle("hidden", true);
    }
  }

  selectStockCode(event) {
    this.stockCodeTarget.value = event.target.closest("tr").querySelector('[data-field="code"]').textContent;
    this.stockCodeTarget.dispatchEvent(new Event('input', { bubbles: true }));
  }

  interpretManifestCode(event) {
    const manifestCode = this.manifestCodeTarget.value;
    this.interpretMessageTarget.textContent = "";
    if (manifestCode.length === 0) { return; }

    const interpreter = new ManifestInterpreter();
    const manifestParams = interpreter.call(manifestCode);

    if (!manifestParams) {
      this.interpretMessageTarget.textContent = "Could not interpret manifest code";
      return;
    }

    if ("stock_code" in manifestParams){
      this.stockCodeTarget.value = manifestParams["stock_code"];
      this.stockCodeTarget.dispatchEvent(new Event('input', { bubbles: true }));
      delete manifestParams["stock_code"];
    }

    Object.keys(manifestParams).forEach(key => {
      const existingValue = this.metaTableTarget.querySelector(`input[data-key="${key}"][name="inventory_item[meta_values][]"]`);
      if (existingValue) {
        existingValue.value = manifestParams[key];
      } else {
        this.addRow();
        const lastRow = this.metaTableTarget.querySelector('tbody tr:last-child');
        const keyInput = lastRow.querySelector('input[name="inventory_item[meta_keys][]"]');
        const valueInput = lastRow.querySelector('input[name="inventory_item[meta_values][]"]');
        keyInput.dataset.key = key;
        keyInput.value = key;
        valueInput.dataset.key = key;
        valueInput.value = manifestParams[key];
      }
    });

    this.handleNewLine(event);
  }
}
